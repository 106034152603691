import { Resume } from './resume'

const pic = `
@@@@@@@@@@@@@@@@@@@@@%%%%%%##%%%%%%%%%&@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@%%%%%&&&@&&&@@&&&&&&&&&&&&&%%%@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@%@&&&&%&&&@@@@@@@&@@@@@@&@@@&&&%%@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@%%%%&%&@@&&%%&&@@@@@@&@@@@@@@@@@@&&%%%@@@@@@@@@@@@@@@
@@@@@@@@@@@@&&&&&%%%&@@@&&&&&&&@@@@@@@@@@@@@@@&@@&&&@@@@@@@@@@@@@
@@@@@@@@@@@@@&%&((((############%&&&&&@@@@@@@@@@@@@&&@@@@@@@@@@@@
@@@@@@@@@@@@@@(///(((((###(###########%&@&&&&@@@@@@@&&@@@@@@@@@@@
@@@@@@@@@@@@@@*/////(((((((((###########%%%%&&&@&&&@&&@@@@@@@@@@@
@@@@@@@@@@@@@**///((((((((((###########%%%&%%&&&&@@@@&&@@@@@@@@@@
@@@@@@@@@@@@@*////((((((((((###########%&&&%&&&&&&%&@@&@@@@@@@@@@
@@@@@@@@@@@@%#%#((((((((#%&&@@&&&&%#####%&&&&&&&&&%%&&%@@@@@@@@@@
@@@@@@@@@@@@(###%##(((##%%%&&&&%%%%%#####%&&&&&@@&%%&%%@@@@@@@@@@
@@@@@@@@@@@@@(&(&&&(((#%%%&%%&&%%%%%######%&&&&%%&%&%(@@@@@@@@@@@
@@@@@@@@@@@@@@/((((/(####################%&&%#%%&%%%%@@@@@@@@@@@@
@@@@@@@@@@@@@/////*(#####################%&&##%%%%%#@@@@@@@@@@@@@
@@@@@@@@@@@@@/*////(#####%%#############%%&&#######@@@@@@@@@@@@@@
@@@@@@@@@@@@@@//((%@%&@&&%#############%%&&%####%&@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@//%&&&&&@@@@@@&%#####%%%%%&&&&#/((&#@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@#&&&&%%&&&&&@@@&%%%%%%&%&&&&%#((%&,,@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@&&%#%%%%%%%%%%&&@@&&&&&&&&&&%##%#*,,,,@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@%#%&@@@&&&&&&&&&&&%&&&&%####(***,,,,,**,,/@@@@@@
@@@@@@@@@@@@@@@@@@%&&&&@&&@@&&&&&&&&&&&%%%%(******,,,,**,***,,,,*
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@&&@@&&%%%%&//*************,*****,,,
@@@@@@@@@@@@@@@@@@@@@/,*(&%%&&%%%%%%%&#*****************,,**,,,,*
@@@@@@@@@@@@@@@@@,,,,,,,(&@%#####%%%/*************,*********,,*,,
@@@@@@@@@@@@.,,,,,****,,*#&@&%%#%/***************,**,******,***,,
`
  .split('\n')
  .map(line => <div className="mar">{line}</div>)

const Website = ({ name, link, description }) => (
  <>
    <div className="text-lg m-4 mb-2">
      <a href={link} target="_blank" rel="noreferrer">
        * {name}
      </a>
    </div>
    <div>
      <p className="ml-10">{description}</p>
    </div>
  </>
)

const responses = {
  help: (
    <>
      <div className="mb-4">Available commands are:</div>

      <div className="flex">
        <div className="flex-1">
          <div className="mb-2">help</div>
          <div>websites</div>
        </div>
        <div className="flex-1">
          <div className="mb-2">email</div>
          <div>resume</div>
        </div>
        <div className="flex-1">
          <div>picture</div>
        </div>
      </div>
    </>
  ),
  email: (
    <>
      <a
        href="mailto:jason@jasonraffile.com?subject=Awesome Website!&body=Do I have a job for you!"
        target="_blank"
        rel="noopener noreferrer"
      >
        Hit me up! (jason@jasonraffile.com)
      </a>
    </>
  ),
  picture: pic,
  websites: (
    <>
      <Website
        link="https://www.meatballcontest.com"
        name="Meatballcontest.com"
        description="An informational site built for the (quasi-) annual Best Meatball in CT contest."
      />
      <Website
        link="https://www.emraceramics.com"
        name="EMRACeramics.com"
        description="Artist website for local potter to display her work."
      />
      <Website
        link="http://whatsthepriceofbitcoin.com"
        name="WhatsThePriceOfBitcoin.com"
        description="Just the price of bitcoin. That's it."
      />
      <Website
        link="http://willaandmaisy.com"
        name="WillaAndMaisy.com"
        description="Website commissioned by my wife to monitor the temperature in our daughters' rooms."
      />
      <Website
        link="http://histar.ai"
        name="HISTAR.AI"
        description="Simple holding page for AR/AI company. Backend done in serverless."
      />
      <Website
        link="http://yardometer.com"
        name="Yard-O-Meter.com"
        description="Calculator for figuring out yardage when a punt or pass goes over the 50-yard line in Football."
      />
    </>
  ),
  resume: <Resume />,
}

export default responses
