export const Resume = () => (
  <>
    <div>
      <img className="w-1/2 mx-auto" src="./name.png" alt="text!" />
      <div className="border-dashed border-2 mx-auto w-2/3">
        <div className="flex flex-col">
          <div className="flex flex-row p-4">
            <div className="px-6 pt-4">
              Hungry Senior Software Engineer with background working
              effectively in dynamic environments. Proficient in Python and
              Kotlin programming languages used to develop software within
              Toast. Has no problem with context switching, designing new
              systems or improving existing ones.
            </div>
          </div>
          <div className="flex flex-row p-4">
            <div className="flex-none w-1/6 p-6 border-r-2 text-right">
              {' '}
              Skillz
            </div>
            <div className="p-6">
              <ul>
                <li className="px-2 pb-2">
                  * Proficient in Kotlin, Python, Java, HCL, Go, BASH, HTML5,
                  CSS 2, Javascript Comfortable with SQL, Postgresql, NoSQL
                  database technologies
                </li>
                <li className="px-2 pb-2"> * Gets down in *NIX environments</li>
                <li className="px-2 pb-2">
                  * Can deftly maneuver cloud-based architecture and has strong
                  familiarity with AWS suite of products
                </li>
                <li className="px-2 ">
                  * Thinks containerized solutions are nifty. Worked, at length,
                  with Docker at Toast and helped the good people at Docker test
                  and release 'Docker App'{' '}
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-row p-4">
            <div className="flex-none w-1/6 p-6 border-r-2 text-right">
              Experience
            </div>
            <div className="p-6 flex-auto">
              <ul>
                <li className="px-2 pb-2">
                  * SENIOR SOFTWARE ENGINEER Toast | Boston, MA 07/2020 to
                  CURRENT
                </li>
                <li className="px-2 pb-2">* Projects include: </li>
                <li className="px-6 pb-2">
                  ** The Toast Developer Portal, or TDP (lovingly called
                  Tadpole). Designed and initially implemented by a former
                  member of the team which I've taken over in the driver's seat.
                  It's the successor to our current, monolithic Jenkins and
                  CI/CD solution, using one-and-done jenkins instances to build
                  code before it even gets to PR.
                </li>
                <li className="px-6 pb-2">
                  ** Frogspawn, a frontend monorepo that I made by pulling our
                  single, giant Single Page Application into three baby SPAs,
                  setting up Tadpole with an extensible, easy-to-dev-for future.
                </li>
                <li className="px-6">
                  ** Lunchbox, uses production containers locally to emulate a
                  Toast environment. Currently in its death throws, I've led the
                  charge for remote Lunchbox where all the services you aren't
                  working on, but are dependencies for your service-under-dev,
                  run in the cloud and are networked onto your machine.
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-row p-4">
            <div className="flex-none w-1/6 p-6 border-r-2 text-right">
              Personal Fun Facts
            </div>
            <div className="p-6 flex-auto">
              <ul>
                <li className="px-2 pb-4">
                  * Loves to cook!
                  <a
                    href="https://www.instagram.com/jraffile/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    &nbsp;Check out the latest dishes.
                  </a>
                </li>
                <li className="px-2 pb-4">
                  * Burgeoning mushroom hunter / forager.
                </li>
                <li className="px-2">
                  <a
                    href="https://j-archive.com/showgame.php?game_id=4534"
                    target="_blank"
                    rel="noreferrer"
                  >
                    * Second-place finisher in Jeopardy!
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-row p-4">
            <div className="flex-none w-1/6 p-6 border-r-2 text-right">
              Education
            </div>
            <div className="p-6 flex-auto">
              <ul>
                <li className="px-2 pb-4">
                  * BOSTON UNIVERSITY - B.A. in History
                </li>
                <li className="px-2">
                  * SANTA MONICA COLLEGE - Certificate of Acheievment in
                  Computer Science
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)
