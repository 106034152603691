import React from 'react'
import responses from './responses'
const Terminal = () => {
  const inputRef = React.useRef(null)
  const [input, setInput] = React.useState('')
  const [terminalText, setTerminalText] = React.useState([])

  return (
    <>
      <Dumpster
        inputRef={inputRef}
        input={input}
        setInput={setInput}
        terminalText={terminalText}
        setTerminalText={setTerminalText}
      />
      <div
        className="bg-black text-white flex flex-col relative h-screen w-screen"
        onClick={() => {
          inputRef.current.focus()
        }}
      >
        <InputBox input={input} terminalText={terminalText} />
      </div>
    </>
  )
}

const Dumpster = ({
  inputRef,
  input,
  setInput,
  terminalText,
  setTerminalText,
}) => {
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      terminalText.push(`> ${input}`)
      setTerminalText(terminalText)
      setInput('')
    } else if (event.key === 'Backspace') {
      setInput(input.slice(0, input.length - 1))
    } else if (event.key === 'Escape') {
      setTerminalText([])
    } else if (event.key.length === 1) {
      setInput(input + event.key)
    }
  }
  React.useEffect(() => {
    if (inputRef) {
      inputRef.current.focus()
      inputRef.current.innerHTML = ''
    }
  })
  return (
    <span
      className="absolute top-0 right-0 text-xs text-transparent"
      ref={inputRef}
      onKeyDown={handleKeyPress}
      contentEditable
      autoFocus
    />
  )
}

const InputBox = ({ input, terminalText }) => {
  const termLines = terminalText.map(line => {
    return <TerminalLine>{line}</TerminalLine>
  })
  const terminalRef = React.useRef()

  React.useEffect(() => {
    terminalRef.current.scrollTop = terminalRef.current.scrollHeight
  })

  return (
    <div
      className="m-2 font-mono overflow-auto h-auto mb-20 mt-auto"
      ref={terminalRef}
    >
      {termLines}
      <div className="absolute bottom-0 left-0 right-0 border-white border-2 p-4 rounded-md">
        <Prompt input={input} />
      </div>
    </div>
  )
}

const TerminalLine = ({ children: text }) => {
  let strippedText = text.replace('>', '').trim()
  if (strippedText === '?') {
    strippedText = 'help'
  }
  const response = responses[strippedText]
  return (
    <>
      <div className="text-s m-2">{text}</div>
      <div className="text-xs m-2">
        {response
          ? response
          : `${text.replace('>', '').trim()}: command not found`}{' '}
      </div>
    </>
  )
}

const Prompt = ({ input }) => {
  return (
    <>
      <div className="flex-auto w-max">
        guest@jasonraffile.com:-$&nbsp;
        <span className="border-none bg-black">{input}</span>
        <span id="blink_me">_</span>
      </div>
    </>
  )
}

export default Terminal
